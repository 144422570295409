import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './theme/ThemeProvider.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

var css = document.createElement("link");
css.rel = 'stylesheet';
css.href = "https://fonts.googleapis.com/css?family=Roboto:300,400,500";

var icons = document.createElement("link");
icons.rel = 'stylesheet';
icons.href = "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined";

document.head.appendChild(css);
document.head.appendChild(icons);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
